import React, { useEffect, useState } from "react";
import momentTZ from "moment-timezone";
import { Layout, ConfigProvider } from "antd";

import {
  ActionTypes,
  useMercuryContext,
  useMercuryDispatch,
} from "./user-context";

import { useLocation, useNavigate } from "react-router-dom";

import "./styles/variables.css";
import "./App.scss";

import HeaderSection from "./main/Header";
import SideAdminMenu from "./main/SideAdminMenu";
import ContentSection from "./main/Content";
import LoginPage from "./user-context/LoginPage";
import { usePermissions } from "./hooks/usePermissions";

function App() {
  const mercuryDispatch = useMercuryDispatch(),
    { isAdmin, loginRequired, flags } = useMercuryContext(),
    navigate = useNavigate(),
    [visibleAdminMenuItems, setVisibleAdminMenuItems] = useState([]),
    location = useLocation(),
    currentLocation = location ? location.pathname : "",
    [isInitialRender, setIsInitialRender] = useState(true);

  const [currentMenuItem, setCurrentMenuItem] = useState("");

  const handleMainMenuSelection = (e: any) => {
    setCurrentMenuItem(e);
  };

  const { hasPermissions } = usePermissions();
  const canViewExtensionJustifications = hasPermissions([{ customerApplication: "Google", permissionName: "Extension Justifications", action: "read" }]);

  // Admin Menu
  // to add a submenu, remove the path key and add submenu key as array.
  const adminMenuItems = [
      {
        id: 0,
        title: "System Administration",
        children: [
          {
            id: 0,
            path: "/manage-users",
            title: "Manage Users",
            active: isAdmin,
            parentId: 0,
          },
          {
            id: 1,
            path: "/manage-roles",
            title: "Manage Roles",
            active: isAdmin,
            parentId: 0,
          },
          {
            id: 2,
            path: "/manage-api-clients",
            title: "Manage Api Clients",
            active: isAdmin,
            parentId: 0,
          },
          {
            id: 3,
            path: "/manage-cost-codes",
            title: "Manage Cost Codes",
            active: isAdmin,
            parentId: 0,
          },
          {
            id: 4,
            path: "/manage-currencies",
            title: "Manage Currencies",
            active: isAdmin,
            parentId: 0,
          },
          {
            id: 5,
            path: "/manage-helix-templates",
            title: "Manage Helix Templates",
            active: isAdmin,
            parentId: 0,
          },
          {
            id: 6,
            path: "/manage-project-rates",
            title: "Manage Project Rates",
            active: isAdmin,
            parentId: 0,
          },
          {
            id: 7,
            path: "/manage-po-types",
            title: "Manage PO Types",
            active: isAdmin,
            parentId: 0,
          },
        ],
      },
      {
        id: 1,
        title: "Google Administration",
        children: [
          {
            id: 10,
            title: "Product Management",
            parentId: 1,
            active: isAdmin,
            submenu: [
              {
                id: 100,
                path: "/manage-products",
                title: "Manage Products",
                active: isAdmin,
                parentId: 10,
              },
              {
                id: 101,
                path: "/manage-product-areas",
                title: "Manage Product Areas",
                active: isAdmin,
                parentId: 10,
              },
              {
                id: 102,
                path: "/product-assignment",
                title: "Product Assignment",
                active: isAdmin,
                parentId: 10,
              },
            ],
          },
          {
            id: 11,
            path: "/manage-helix-template-mappings",
            title: "Manage Google Helix Template Mappings",
            active: isAdmin,
            parentId: 1,
          },
          {
            id: 12,
            path: "/manage-extension-justifications",
            title: "Manage Extension Justifications",
            active: canViewExtensionJustifications,
            parentId: 1,
          },
          {
            id: 13,
            path: "/manage-workflow-types",
            title: "Manage Workflow Types",
            active: isAdmin,
            parentId: 1,
          },
          {
            id: 14,
            path: "/quarantined-projects",
            title: "Quarantined projects",
            active: isAdmin && flags.manageDownload,
            parentId: 1,
          },
          {
            id: 15,
            path: "/downloadProject",
            title: "Download project",
            active: isAdmin && flags.manageDownload,
            parentId: 1,
          },
          {
            id: 16,
            path: "/failed-download",
            title: "Failed to download",
            active: isAdmin && flags.manageDownload,
            parentId: 1,
          },
        ],
      },
    ];

  // return only active ones
  const filterVisibleMenuItems = (menuItems: any) => {
    let result: any = [];

    menuItems.forEach((item: any) => {
      if (item.active && item?.submenu) {
        result.push({
          ...item,
          submenu: item?.submenu.filter((i: any) => i.active),
        });
      } else if (item.active) {

          result.push(item);
        
      }
    });

    return result;
  };

  const getActiveMainMenu = (location: string) => {
    if (location === "/" || location.includes("/project/")) {
      return "projects";
    }

    if (location === "/invoicing" || location.includes("/invoicing-project/")) {
      return "invoicing";
    }

    if (location === "/reports" || location.includes("/reports/")) {
      return "reports";
    }

    if (location === "/adjustments" || location.includes("/adjustments/")) {
      return "adjustments";
    }

    switch (location) {
      case "/plps":
        return "plps";
      case "/documents":
        return "documents";
      case "/linguist-management":
        return "linguist-management";
      case "/extension-requests":
        return "extension-requests";

      default:
        return "activeMainMenuNotSet";
    }
  };

  useEffect(() => {
    //fix to display menu on load based on router location
    if (isInitialRender) {
      const matchedMenu: any[] = [];
      setIsInitialRender(false);

      adminMenuItems.forEach((menu) => {
        menu.children.forEach((child) => {
          if (child.path) {
            if (child.path === currentLocation) {
              matchedMenu.push(menu);
            }
          } else {
            child.submenu!.forEach((sub) => {
              if (sub.path === currentLocation) {
                matchedMenu.push(menu);
              }
            });
          }
        });
      });

      matchedMenu.length &&
        setVisibleAdminMenuItems(() =>
          filterVisibleMenuItems(matchedMenu[0].children)
        );

      // main menu - check just on load
      handleMainMenuSelection(getActiveMainMenu(currentLocation));
    }
  }, [currentLocation, isInitialRender]);

  const closeAdminMenu = () => {
    setVisibleAdminMenuItems([]);
  };

  const openAdminMenu = (items: any) => {
    const filteredItems = filterVisibleMenuItems(items);

    setVisibleAdminMenuItems(filteredItems);
    if (filteredItems.length > 0) {
      navigate(
        filteredItems[0].submenu
          ? filteredItems[0].submenu[0].path
          : filteredItems[0].path
      );
    } else {
      console.info('No menu items to display. ')
    }
  };

  useEffect(() => {
    const timezoneFromLocalStorage = localStorage.getItem("timezone");

    if (timezoneFromLocalStorage) {
      mercuryDispatch({
        type: ActionTypes.UPDATE_TIMEZONE,
        payload: timezoneFromLocalStorage,
      });
    } else {
      // guess the timezone from moment
      const timezoneFromMoment = momentTZ.tz.guess();

      mercuryDispatch({
        type: ActionTypes.UPDATE_TIMEZONE,
        payload: timezoneFromMoment,
      });
      localStorage.setItem(`timezone`, timezoneFromMoment);
    }

    // fix to update timezone on all opened tabs
    window.addEventListener(`storage`, (e) => {
      if (e.key === "timezone") {
        mercuryDispatch({
          type: ActionTypes.UPDATE_TIMEZONE,
          payload: e.newValue,
        });
      }
    });
  }, [mercuryDispatch]);

  if (loginRequired) {
    return <LoginPage />;
  }

  // https://ant.design/docs/react/customize-theme
  // Note: This doesn't apply to modals, notifications and other components
  // that are being created outside of DOM
  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "#008080",
            colorLink: "#00a89f",
            colorInfo: "#00a89f",
            colorSuccess: "00a89f",
            fontFamily: "'Noto Sans', sans-serif",
            fontFamilyCode: "'Noto Sans', sans-serif",
            colorTextBase: "#282c36",
            borderRadius: 4,
            // Map Token
            colorInfoActive: "#008080",
            colorPrimaryHover: "#008080",
            colorLinkActive: "#00a89f",
            colorPrimaryTextHover: "#00a89f",
            colorInfoTextHover: "#008080",
            // Alias Token
            colorBgContainer: "#fafafa",
          },
        }}
      >
        <Layout className="layout">
          <HeaderSection
            adminMenuData={{
              adminMenuItems,
              openAdminMenu,
              closeAdminMenu,
              currentMenuItem,
              handleMainMenuSelection,
            }}
          />
          <Layout>
            {/*// @ts-ignore*/}
            {<SideAdminMenu items={visibleAdminMenuItems} />}
            <ContentSection />
          </Layout>
        </Layout>
      </ConfigProvider>
    </div>
  );
}

export default App;
