import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Collapse, Tabs, TabsProps } from "antd";
import { Error } from "../../../stories/Errors/Error";
import LoadingStar from "../../../ui/LoadingStar";
import BackTopButton from "../../../ui/BackTopButton";
import { useMercuryContext } from "../../../user-context";
import {
  CreditCardOutlined,
  HistoryOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import SharingTab from "../../Project/ProjectDetails/SharingTab";
import StatusHistory from "../../StatusHistory";
import Breadcrumbs from "../../../stories/BreadCrumb/Breadcrumbs";
import DocumentGeneralInformation from "./DocumentGeneralInformation";
import DocumentPrefillStatistics from "./DocumentPrefillStatistics";
import { checkPermissions } from "../../../Utilities/checkPermissions";

interface State {
  error?: any;
  loading: boolean;
  details?: any;
}

class DocumentDetails extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: undefined,
      loading: false,
      details: undefined,
    };
  }

  componentDidMount(): void {
    this.fetch();
  }

  async fetch() {
    this.setState({ loading: true });
    try {
      const { data } = await this.props.context.gpClient.get(
        `/google-gp-v1/document/${this.props.documentId}`
      );
      this.setState({ details: data, loading: false });
    } catch (e: any) {
      this.setState({ error: e, loading: false });
    }
  }

  reload = async () => {};

  documentDetails() {
    const items = [
      {
        key: "1",
        label: "General Information",
        children: <DocumentGeneralInformation details={this.state.details} />,
        className: "general-information",
      },
    ];

    return (
      <Collapse
        defaultActiveKey={["1"]}
        items={items}
        style={{ marginBottom: "10px" }}
      />
    );
  }

  tabs() {
    const { details } = this.state,
      { shortId } = details;

    const userPermissions = this.props.context?.userDetails?.permissions || [];
    const canReadProjectSharing = checkPermissions(userPermissions, [{ customerApplication: "Google", permissionName: "Project Sharing", action: "read" }]);

    const tabItems: TabsProps["items"] = [
      {
        key: "1",
        label: (
          <span>
            <CreditCardOutlined /> Prefill Statistics
          </span>
        ),
        children: (
          <DocumentPrefillStatistics
            prefillStatistics={details?.prefillStatistics}
          />
        ),
      },
      ...canReadProjectSharing ? [{
        key: "2",
        label: (
          <span>
            <ShareAltOutlined /> Document Sharing
          </span>
        ),
        children: (
          <SharingTab
            targetLanguageCode={details?.targetLanguageCode}
            documentId={details?.documentId}
            shortId={shortId}
          />
        ),
      }] : [],
      {
        key: "3",
        label: (
          <span>
            <HistoryOutlined /> Status History
          </span>
        ),
        children: (
          <StatusHistory
            statusHistory={details?.customVendorDocumentMetadata?.statusHistory}
          />
        ),
      },
    ];

    return <Tabs defaultActiveKey="1" size="small" items={tabItems} />;
  }

  render() {
    const { details } = this.state;
    const { shortId, title, documentId: docId  } = details || {};
    const { documentId } = this.props;

    if (this.state.error) {
      return (
        <Error
          reload={this.reload}
          loading={this.state.loading}
          error={JSON.stringify(this.state.error)}
        />
      );
    }

    if (this.state.loading || !this.state.details) {
      return (
        <LoadingStar
          logo={true}
          title={
            <div>
              Retrieving details for project: <br /> {documentId}{" "}
            </div>
          }
        />
      );
    }

    return (
      <>
        <Helmet>
          <title>
            {shortId} - {title} - Mercury © RWS
          </title>
        </Helmet>
        <div className="project-details-content">
          <Breadcrumbs
            separator="-"
            items={[
              {
                key: "projectId",
                label: details?.shortId,
                href: `/project/${shortId}`,
                tooltip: "Project ID",
                color: "var(--primary-color)",
              },
              {
                key: "plpId",
                label: details?.targetLanguageCode,
              },
              {
                key: "documentId",
                label: docId?.split("/")?.[1],
              },
              {
                key: "documentName",
                label: details?.title,
                color: "var(--primary-color)",
              },
            ]}
          />
          <div
            style={{ padding: "5px 0 10px 0" }}
            className="project-details-body"
          >
            {this.documentDetails()}
            {this.tabs()}
            <BackTopButton />
          </div>
        </div>
      </>
    );
  }
}

const DocumentDetailsPage = function (props: any) {
  const context = useMercuryContext(),
    { documentId } = useParams();

  return (
    <DocumentDetails
      context={context}
      location={props.location}
      documentId={documentId}
    />
  );
};

export default DocumentDetailsPage;
