import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Collapse, Space, Tabs, TabsProps } from "antd";
import { Error } from "../../../stories/Errors/Error";
import LoadingStar from "../../../ui/LoadingStar";
import Breadcrumbs from "../../../stories/BreadCrumb/Breadcrumbs";
import BackTopButton from "../../../ui/BackTopButton";
import { useMercuryContext } from "../../../user-context";
import ManualProjectInformation from "./ManualProjectInformation";
import {
  CreditCardOutlined,
  HistoryOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import StatusHistory from "../../StatusHistory";
import RequestedLanguages from "./RequestedLanguages";
import ManageProject from "../ManageProject";
import { checkPermissions } from "../../../Utilities/checkPermissions";
import UniversalButton from "../../../ui/UniversalButton";

interface State {
  error?: any;
  loading: boolean;
  details?: any;
}

class ProjectDetails extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: undefined,
      loading: false,
      details: undefined,
    };
  }

  componentDidMount(): void {
    this.fetch();
  }

  async fetch(updating?: boolean, setUpdating?: any) {
    if (!updating) {
      this.setState({ loading: true });
    }
    try {
      const { data } = await this.props.context.gpClient.get(
        `/google-gp-v1/manualProjects/${this.props.id}`
      );
      this.setState({ details: data, loading: false });
      if (updating) {
        setUpdating(false);
      }
    } catch (e: any) {
      this.setState({ error: e, loading: false });
    }
  }

  reload = async () => {};

  projectDetails() {
    const items = [
      {
        key: "1",
        label: "General Information",
        children: (
          <ManualProjectInformation
            fetch={(setUpdating: any) => this.fetch(true, setUpdating)}
            details={this.state.details}
          />
        ),
        className: "general-information",
      },
    ];

    return (
      <Collapse
        defaultActiveKey={["1"]}
        items={items}
        style={{ marginBottom: "10px" }}
      />
    );
  }

  tabs() {
    const { details } = this.state;
    const tabItems: TabsProps["items"] = [
      {
        key: "1",
        label: (
          <span>
            <CreditCardOutlined /> Requested Languages
          </span>
        ),
        children: (
          <RequestedLanguages languages={details?.requestedLanguages} />
        ),
      },
      {
        key: "2",
        label: (
          <span>
            <HistoryOutlined /> Production Status History
          </span>
        ),
        children: (
          <StatusHistory statusHistory={details?.productionStatusHistory} />
        ),
      },
      {
        key: "3",
        label: (
          <span>
            <HistoryOutlined /> Quote Status History
          </span>
        ),
        children: <StatusHistory statusHistory={details?.quoteStatusHistory} />,
      },
    ];
    return <Tabs defaultActiveKey="1" size="small" items={tabItems} />;
  }

  actions() {
    // @ts-ignore
    const userPermissions = this.props.context?.userDetails?.permissions || [];
    const canEditProject = checkPermissions(userPermissions, [
      {
        customerApplication: "Google",
        permissionName: "Manual Projects",
        action: "edit",
      },
    ]);
    const items: TabsProps["items"] = [
      {
        key: "1",
        label: "Actions",
        className: "actions",
        children: (
          <Space
            size="large"
            style={{
              width: "100%",
              alignItems: "flex-start",
              margin: "15px 16px",
            }}
          >
            {canEditProject && (
              <div onClick={(e) => e.stopPropagation()}>
                <ManageProject
                  label="Edit"
                  initialValues={this.state.details}
                  reload={(setUpdating: any) => this.fetch(true, setUpdating)}
                />
              </div>
            )}

            <UniversalButton
              clickMethod={() => this.fetch()}
              text="Refresh"
              icon={<ReloadOutlined />}
              type={undefined}
            />
          </Space>
        ),
      },
    ];
    return (
      <Collapse
        defaultActiveKey={["1"]}
        items={items}
        style={{ marginBottom: "10px" }}
      />
    );
  }

  render() {
    const { details } = this.state;
    const { projectName, product } = details || {};
    const { id } = this.props;
    if (this.state.error) {
      return (
        <Error
          reload={this.reload}
          loading={this.state.loading}
          error={JSON.stringify(this.state.error)}
        />
      );
    }

    if (this.state.loading || !this.state.details) {
      return (
        <LoadingStar
          logo={true}
          title={
            <div>
              Retrieving details for project: <br /> {id}
            </div>
          }
        />
      );
    }

    return (
      <>
        <Helmet>
          <title>
            {projectName} - {product} - Mercury © RWS
          </title>
        </Helmet>
        <div className="project-details-content">
          <Breadcrumbs
            separator="-"
            items={[
              {
                key: "projectName",
                label: details?.projectName,
                tooltip: "Project Name",
                color: "var(--primary-color)",
              },
              {
                key: "product",
                label: details?.product,
                tooltip: "Product",
              },
            ]}
          />
          <div
            style={{ padding: "5px 0 10px 0" }}
            className="project-details-body"
          >
            {this.projectDetails()}
            {this.actions()}
            {this.tabs()}
            <BackTopButton />
          </div>
        </div>
      </>
    );
  }
}

const ManualProjectDetails = function (props: any) {
  const context = useMercuryContext(),
    { id } = useParams();

  return <ProjectDetails context={context} location={props.location} id={id} />;
};

export default ManualProjectDetails;
