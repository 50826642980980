import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Row,
  Col,
  InputNumber,
  Typography,
  Divider,
} from "antd";
import { fetchLanguageCodes } from "../LanguageCode.filter";
import { useMercuryContext } from "../../user-context";
import {
  failedNotification,
  successfulNotification,
} from "../../utils/notifications";
import {
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import SessionStorage from "../../Utilities/LocalStorage";
import dayjs from "dayjs";
import { fetchManualProjectFields } from "./manualProjectFields";
import { fetchProducts } from "../Product.filter";

const { Option } = Select;

const booleanOptions = [
  { title: "Yes", value: true },
  { title: "No", value: false },
];

interface Data {
  title: string;
  value: string;
}

const storedCostCodes = new SessionStorage("costCode");
const storedProducts = new SessionStorage("product");
const targetLanguageCode = new SessionStorage("targetLanguageCode");
const manualProjectFields = new SessionStorage("manualProjectFields");

const ManageProject = ({ reload, label, initialValues }: any) => {
  const context = useMercuryContext();
  const [products, setProducts] = useState<any>([]);
  const { gpClient, client } = useMercuryContext();
  const [fieldsData, setFieldsData] = useState<Record<string, any> | null>(
    null
  );
  const [loadingFields, setLoadingFields] = useState<boolean>(true);
  const [, setFieldError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [languages, setLanguages] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [costCodes, setCostCodes] = useState<any[]>([]);
  const [loadingCostCodes, setLoadingCostCodes] = useState<boolean>(true);
  const isEditMode = label === "Edit";

  const [form] = Form.useForm();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (initialValues) {
      const formattedValues = {
        ...initialValues,
        projectDropDate: initialValues.projectDropDate
          ? dayjs(initialValues.projectDropDate)
          : null,
        headsUpDate: initialValues.headsUpDate
          ? dayjs(initialValues.headsUpDate)
          : null,
        testingStart: initialValues.testingStart
          ? dayjs(initialValues.testingStart)
          : null,
        quoteSubmission: initialValues.quoteSubmission
          ? dayjs(initialValues.quoteSubmission)
          : null,
        requestedLanguages: initialValues.requestedLanguages
          ? initialValues.requestedLanguages.map((item: any) =>
              Object.fromEntries(
                Object.entries(item).map(([key, value]) =>
                  key.toLowerCase().includes("date") &&
                  typeof value === "string"
                    ? [key, dayjs(value)]
                    : [key, value]
                )
              )
            )
          : [{}],
      };

      form.setFieldsValue(formattedValues);
    }
  }, [initialValues]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingCostCodes(!storedCostCodes?.data);
        setLoadingFields(!manualProjectFields?.data);

        const fetchCostCodes = !storedCostCodes?.data
          ? client.get("/core-ccs-v1").then(({ data }: any) => {
              const rows = data.map((item: any) => ({
                title: item.code,
                value: item.code,
              }));
              storedCostCodes.data = rows;
              setCostCodes(rows);
            })
          : Promise.resolve(setCostCodes(storedCostCodes.data));

        const fetchFieldsData = !manualProjectFields?.data
          ? fetchManualProjectFields(gpClient, [
              "projectType",
              "priority",
              "complexity",
              "surface",
              "productionStatus",
              "huAccuracy",
              "quoteStatus",
              "so",
            ]).then((data) => {
              manualProjectFields.data = data;
              setFieldsData(data);
            })
          : Promise.resolve(setFieldsData(manualProjectFields.data));

        const fetchLanguages = !targetLanguageCode?.data
          ? fetchLanguageCodes(context).then((data) => {
              targetLanguageCode.data = data;
              setLanguages(data);
            })
          : Promise.resolve(setLanguages(targetLanguageCode.data));

        const fetchProductsData = !storedProducts?.data
          ? fetchProducts(context).then((data: any[]) => {
              storedProducts.data = data;
              setProducts(data);
            })
          : Promise.resolve(setProducts(storedProducts.data));

        await Promise.all([
          fetchCostCodes,
          fetchFieldsData,
          fetchLanguages,
          fetchProducts,
        ]);
      } catch (error: any) {
        setFieldError(error.toString());
      } finally {
        setLoadingCostCodes(false);
        setLoadingFields(false);
      }
    };

    fetchData();
  }, []);

  const formatKey = (key: string) => {
    return key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase())
      .trim();
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);

      try {
        let response;

        if (isEditMode) {
          const data = {
            ids: [initialValues?.id],
            body: {
              ...values,
            },
          };
          response = await gpClient.put(
            "/google-gp-v1/manualProjects/merge",
            data
          );
        } else {
          response = await gpClient.post(
            "/google-gp-v1/manualProjects",
            values
          );
        }

        if (response?.status === 200 || response?.status === 201) {
          successfulNotification(
            "Success",
            isEditMode
              ? "This will be updated in real-time"
              : "The project has been created!"
          );

          if (isEditMode) {
            reload(setLoading);
          } else {
            reload();
          }

          setIsModalOpen(false);
          form.resetFields();
        } else {
          throw new Error("Unexpected response from server");
        }
      } catch (error: any) {
        const errorData = error?.response?.data || {};
        const errorMessage =
          errorData?.message ||
          errorData?.errorResponse?.errmsg ||
          errorData?.details?.toString() ||
          "We've found an internal error saving data. Please, try again";

        failedNotification("Error", errorMessage);
      }
    } catch (validationError: any) {
      const missingKeys = validationError.errorFields
        .map((field: any) => {
          return formatKey(field?.name?.join("."));
        })
        .join(", ");

      failedNotification(
        "Validation failed",
        `Please fill all required fields: ${missingKeys}`
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFloatInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !/[0-9.]/.test(event.key) &&
      !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(
        event.key
      )
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <Button
        type="primary"
        onClick={toggleModal}
        icon={isEditMode ? <EditOutlined /> : <PlusOutlined />}
      >
        {label} Project
      </Button>
      <Modal
        open={isModalOpen}
        className="create-project-modal"
        title={`${label} Project`}
        okText="Submit"
        cancelText="Cancel"
        onCancel={toggleModal}
        maskClosable={false}
        onOk={handleSubmit}
        destroyOnClose={true}
        confirmLoading={loading}
        width={800}
        forceRender
      >
        <Form
          name="manage-project-form"
          form={form}
          layout="vertical"
          onValuesChange={(changedValues, allValues) => {
            if (
              "headsUpReceived" in changedValues &&
              changedValues.headsUpReceived === false
            ) {
              form.setFieldsValue({ headsUpDate: allValues.projectDropDate });
            }
          }}
        >
          <Form.Item
            label="Buganizer"
            name="buganizerId"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter buganizer link" />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Products"
                name="product"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select allowClear showSearch placeholder="Select product">
                  {products?.map((el: Data) => (
                    <Option key={el?.title} value={el?.value}>
                      {el?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Project Name"
                name="projectName"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Enter project name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Project Type"
                name="testingProjectType"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  loading={loadingFields}
                  placeholder="Select project type"
                  allowClear
                  showSearch
                >
                  {fieldsData?.projectType?.map((el: Data) => (
                    <Option key={el?.title} value={el?.value}>
                      {el?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Project Drop date"
                name="projectDropDate"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="LPT PM Notes"
            name="lptPMNotes"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input.TextArea rows={3} placeholder="Enter LPT PM Notes" />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Priority"
                name="priority"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  loading={loadingFields}
                  allowClear
                  showSearch
                  placeholder="Select priority"
                >
                  {fieldsData?.priority?.map((el: Data) => (
                    <Option key={el?.title} value={el?.value}>
                      {el?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Test cases/locale"
                name="testCases"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <InputNumber
                  placeholder="Enter Test cases/locale"
                  onKeyDown={handleFloatInput}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Typography style={{ marginBottom: 10 }}>
            Requested Languages
          </Typography>
          <Form.List name="requestedLanguages" initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <div key={key}>
                    {fields?.length > 1 && (
                      <Divider style={{ borderWidth: "2px" }} />
                    )}
                    <Row gutter={16} align="middle">
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          label="Target Language Code"
                          name={[name, "targetLanguageCode"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Select
                            allowClear
                            showSearch
                            placeholder="Select language code"
                          >
                            {languages?.map((el) => (
                              <Option key={el?.title} value={el?.value}>
                                {el?.title}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          label="Google Requested Date"
                          name={[name, "googleRequestedDate"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          label="Estimated Delivery Date"
                          name={[name, "estimatedDeliveryDate"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={fields?.length > 1 ? 10 : 12}>
                        <Form.Item
                          {...restField}
                          label="RWS Delivery Date"
                          name={[name, "rwsDeliveryDate"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        {fields?.length > 1 && (
                          <MinusCircleOutlined
                            onClick={() => remove(name)}
                            style={{
                              fontSize: 18,
                              color: "red",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                ))}
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                  style={{ margin: "12px 0 18px 0" }}
                >
                  Add More Languages
                </Button>
              </>
            )}
          </Form.List>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Surface"
                name="surface"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  loading={loadingFields}
                  allowClear
                  showSearch
                  placeholder="Select surface"
                >
                  {fieldsData?.surface?.map((el: Data) => (
                    <Option key={el?.title} value={el?.value}>
                      {el?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Production Status"
                name="productionStatus"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select production status"
                  loading={loadingFields}
                >
                  {fieldsData?.productionStatus?.map((el: Data) => (
                    <Option key={el?.title} value={el?.value}>
                      {el?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Heads-up Received"
                name="headsUpReceived"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select allowClear showSearch placeholder="Select hu received">
                  {booleanOptions?.map((el: any) => (
                    <Option key={el?.title} value={el?.value}>
                      {el?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Heads-up date"
                name="headsUpDate"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="HU (heads-up) Accuracy"
                name="headsUpAccuracy"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  loading={loadingFields}
                  allowClear
                  showSearch
                  placeholder="Select accuracy"
                >
                  {fieldsData?.huAccuracy?.map((el: Data) => (
                    <Option key={el?.title} value={el?.value}>
                      {el?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Testing Start"
                name="testingStart"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Complexity"
                name="complexity"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  loading={loadingFields}
                  allowClear
                  showSearch
                  placeholder="Select complexity"
                >
                  {fieldsData?.complexity?.map((el: Data) => (
                    <Option key={el?.title} value={el?.value}>
                      {el?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Quote Submission"
                name="quoteSubmission"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Quote Status"
                name="quoteStatus"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  loading={loadingFields}
                  allowClear
                  showSearch
                  placeholder="Select quote status"
                >
                  {fieldsData?.quoteStatus?.map((el: Data) => (
                    <Option key={el?.title} value={el?.value}>
                      {el?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Client Quote (link)"
                name="clientQuoteLink"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Enter link" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Internal Quote (link)"
                name="internalQuoteLink"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Enter link" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="SO"
                name="so"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  loading={loadingFields}
                  allowClear
                  showSearch
                  placeholder="Select so"
                >
                  {fieldsData?.so?.map((el: Data) => (
                    <Option key={el?.title} value={el?.value}>
                      {el?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Client PO #"
                name="clientPO"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Enter client po" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Tester hours"
                name="testerHours"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <InputNumber
                  placeholder="Enter hours"
                  onKeyDown={handleFloatInput}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Test lead hours"
                name="testLeadHours"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <InputNumber
                  placeholder="Enter hours"
                  onKeyDown={handleFloatInput}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Internal cost"
                name="internalCost"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <InputNumber
                  placeholder="Enter cost"
                  style={{ width: "100%" }}
                  onKeyDown={handleFloatInput}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Cost Code"
                name="costCode"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  loading={loadingCostCodes}
                  allowClear
                  showSearch
                  placeholder="Select cost code"
                >
                  {costCodes?.map((el: Data) => (
                    <Option key={el?.title} value={el?.value}>
                      {el?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ManageProject;
