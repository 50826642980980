import React, { useMemo, useState } from "react";
import { Button } from "antd";
import SesameRequest from "./SesameRequest";
import { fetchLanguageCodes } from "../../Search/LanguageCode.filter";
import { useMercuryContext } from "../../user-context";
import { fetchProductArea } from "../../Search/ProductArea.filter";

interface FormData {
  username?: string;
  email?: string;
  password?: string;
}

export interface SesameAccountDialogProps {
  onSubmit: (data: FormData, callback: () => void) => void;
  buttonLabel: string;
  requesterEmail: string;
  disableOptions?: number[];
  isMenuItem?: boolean;
  setIsMainOpen?: any;
}

const SesameAccountDialog: React.FC<SesameAccountDialogProps> = ({
  onSubmit,
  buttonLabel,
  requesterEmail,
  disableOptions,
  isMenuItem,
  setIsMainOpen
}) => {
  const context = useMercuryContext();
  const [visible, setVisible] = useState(false);
  const [languages, setLanguages] = useState<any[]>([]);
  const [productLines, setProductLines] = useState<any[]>([]);

  const memoizedLanguages = useMemo(() => {
    if (languages.length === 0) {
      fetchLanguageCodes(context).then(setLanguages);
    }
    return languages;
  }, [languages, context]);

  const memoizedProductLines = useMemo(() => {
    if (productLines.length === 0) {
      fetchProductArea(context).then(setProductLines);
    }
    return productLines;
  }, [productLines, context]);

  const toggleModal = () => {
    setIsMainOpen(false)
    setVisible(!visible);
  };

  const onSuccess = (resetFields: () => void) => {
    resetFields();
    toggleModal();
  };

  const onClose = (resetFields: () => void) => {
    resetFields();
    setVisible(false);
  };

  const handleFormSubmit = (data: any, resetFields: () => void) => {
    onSubmit(data, () => onSuccess(resetFields));
  };

  return (
    <div>
      <Button
        type={isMenuItem ? "text" : "primary"}
        onClick={() => toggleModal()}
        className={isMenuItem ? "new-request-btn" : "admin-link new-request-btn"}
      >
        {buttonLabel}
      </Button>
      {visible ? (
        <SesameRequest
          isMenuItem={isMenuItem}
          visible={visible}
          onClose={onClose}
          onSubmit={handleFormSubmit}
          requesterEmail={requesterEmail}
          languages={memoizedLanguages}
          productLines={memoizedProductLines}
          disableOptions={disableOptions}
        />
      ) : null}
    </div>
  );
};

export default SesameAccountDialog;
