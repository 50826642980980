import { Table } from "antd";
import TimezoneConverter from "../../../timezone-converter";

const RequestedLanguages = ({ languages }: any) => {
  const languagesDetails = languages?.map((el: any, id: number) => {
    return {
      key: id,
      ...el,
    };
  });
  return (
    <div style={{ margin: "0 10px", border: "1px solid #d9d9d9" }}>
      <Table
        dataSource={languagesDetails}
        columns={[
          {
            title: "Target Language Code",
            dataIndex: "targetLanguageCode",
            align: "left",
            render: (text) => text,
          },
          {
            title: "Estimated Delivery Date",
            dataIndex: "estimatedDeliveryDate",
            align: "left",
            render: (text) => {
              return <TimezoneConverter date={text} />;
            },
          },
          {
            title: "Google Requested Date",
            dataIndex: "googleRequestedDate",
            align: "left",
            render: (text) => {
              return <TimezoneConverter date={text} />;
            },
          },
          {
            title: "Rws Delivery Date",
            dataIndex: "rwsDeliveryDate",
            align: "left",
            render: (text) => {
              return <TimezoneConverter date={text} />;
            },
          },
        ]}
        pagination={false}
      />
    </div>
  );
};

export default RequestedLanguages;
